<template>
  <div>
    <app-detail-header :show-toggle="true"
      v-if="entity && !uninterruptedView"
      :entity="entity"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="entity && !entity.deleted && !entity.isNew && !readOnlyView && !isInvoiced"
      :show-save="entity && !readOnlyView"
      :show-lock="entity && !entity.isNew"
      :show-price-parts-msg="!entity.isNew && canViewPricePartsMsg"
      :show-quote-template="entity && !readOnlyView"
      :buttons-enabled="isHeaderButtonEnabled"
      :quote-template-disabled="quoteTemplateDisabled"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <quote-side-menu v-if="entity && !uninterruptedView"
          :entity-name="`${entity.prefix}${entity.quoteNo}${entity.suffix}${entity.subQuoteNo !== 0 ? '/' + entity.subQuoteNo : ''}`"
          :price-parts-return-route="pricePartsReturnRoute"
          v-model="entity"
          ref="sideMenu"
          @select-subquote="changeQuote" />
      </div>
      <div class="column">
        <router-view v-if="entity && vehicle && customer && insurer"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name"
          :vehicle.sync="vehicle"
          :customer.sync="customer"
          :insurer.sync="insurer"
          :next-line-number="nextLineNumber"
          @onOpgCodeChanged="onOpgCodeChanged"
          :read-only-view="readOnlyView"
          :audanet-lock="audanetLock"
          :price-parts-return-route="pricePartsReturnRoute"
          :snapshot-diff="snapshotDiff"
          @getEntity="getEntity"
          @select-vehicle="selectVehicle"
          @select-customer="selectCustomer"
          @select-insurer="selectInsurer"
          @save="save"
          @authorise-quote="authoriseQuote"
          @request-authority-quote="requestAuhtority"
          @revert-authority="revertAuthority"
          @cacnel-authority="cancelAuthority"
          @cacnel-request="cancelRequest"
          @disable-header-buttons="disableHeaderButtons"
          @downloadPartsCheckPrice="downloadPartsCheckPrice" />
      </div>
    </div>
    <print-options-modal v-if="isPrintOptionsActive"
      :active.sync="isPrintOptionsActive"
      @cancel="closeModal()"
      @ok="closeModal()"
      :show-icon="true"
      :attach-disabled="false"
      :cancel-disabled="false"
      :value="entity">
      <p slot="text-title">Print Options</p>
    </print-options-modal>
    <invoice-print-options-modal v-if="isInvoicePrintOptionsActive"
      :active.sync="isInvoicePrintOptionsActive"
      @ok="isInvoicePrintOptionsActive = false"
      @cancel="isInvoicePrintOptionsActive = false"
      :show-icon="true"
      :cancel-disabled="false"
      :value="entity">
      <p slot="text-title">Invoice Print Options</p>
    </invoice-print-options-modal>
    <vue-fab v-show="showQuoteActionButton"
      position="bottom-right"
      :z-index="30"
      :actions="fabActions"
      main-tooltip="Quote Actions"
      @newAdditional="newAdditional"
      @sendSms="sendSms"
      @copyQuote="showCopyQuoteModal"
      @exportQuote="exportQuote" />
    <sms-modal v-if="isSmsModalActive"
      :active.sync="isSmsModalActive"
      v-model="smsDetails"
      @close="closeSmsModal" />
    <copy-quote-modal v-if="isCopyQuoteModalActive"
      :active.sync="isCopyQuoteModalActive"
      v-model="entity"
      :image-ids="imageIds"
      @copy="copyQuote"
      @cancel="isCopyQuoteModalActive = false" />
    <quote-export-modal v-if="isQuoteExportModalActive"
      :active.sync="isQuoteExportModalActive"
      v-model="entity"
      :vehicle="quoteVehicle"
      :default-email="insurerDefaultEmail"
      @close="closeQuoteExportModal" />
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Quote</p>
      <p slot="text-content">
        Quote
        <span class="has-text-primary has-text-weight-bold">{{ `${deleteQuoteMessage}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
    <confirm-modal :active.sync="isCustomerCreateModifyModalActive"
      @ok="createVehicleCustomer"
      @cancel="modifyCustomer"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <div slot="text-title">New Owner</div>
      <div slot="text-content">
        Customer details have changed. Is this the new owner of the vehicle?
      </div>
    </confirm-modal>
    <save-conflict-modal v-if="entity"
      :active.sync="isSaveConflictModalActive"
      @merge="conflictMerge"
      @reload="conflictReload"
      @close="isSaveConflictModalActive = false">
      <p slot="text-title">Quote Conflict</p>
      <p slot="text-content">
        Quote <span class="has-text-primary has-text-weight-bold">{{ `${entity.prefix}${entity.quoteNo}${entity.suffix}` }}</span> is newer on the server. Reload/Merge/Cancel?
        <br><br>
        <span class="has-text-weight-bold">Reload</span> - <span class="is-italic">Reload quote and lose changes</span><br>
        <span class="has-text-weight-bold">Merge</span> - <span class="is-italic">Local quote changes take precedence. Added items will be merged</span><br>
      </p>
    </save-conflict-modal>
    <!-- <pre v-if="$v">{{ $v }}</pre> -->
    <xml-viewer-modal v-if="isXmlViewerModalActive"
      :active.sync="isXmlViewerModalActive"
      :quote-id="entity.quoteId"
      :message-type="messageType"
      @close="isXmlViewerModalActive = false">
      <p slot="text-title">View {{ messageType }} Messages</p>
    </xml-viewer-modal>
    <quote-template-apply-modal v-if="isQuoteTemplateModalActive"
      :active.sync="isQuoteTemplateModalActive"
      :quote-times-type="entity.labourType"
      :vehicle-body-id="vehicle.bodyId"
      @add="addRepairQuoteTemplateItems">
      <p slot="text-title">Apply Quote Template</p>
    </quote-template-apply-modal>
  </div>
</template>

<script>
import QuoteSideMenu from './QuoteSideMenu'
import AppDetailHeader from '@/components/AppDetailHeader'
import QuoteRoutes from './route-types'
import QuoteValidation from './QuoteValidation'
import {
  AppHeaderButtonTypes,
  RemarkTypes,
  EventHubTypes,
  PhoneTypes,
  InvoiceTypes,
  LabourTimeTypes,
  ItemCategoryTypes,
  QuoteItemStatusTypes,
  AssetTypes,
  PaintGroupTypes,
  JobStageTypes,
  OrmQuoteStatusTypes,
  EmailAssetTypes
} from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash.clonedeep'
import _isEmpty from 'lodash/isEmpty'
import { VueFab } from '@/components/VueFab'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
// import _debounce from 'lodash.debounce'
import { UnsavedModal, ConfirmModal, SaveConflictModal } from '@/components/BulmaModal'
import { Emailer } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import StoreUtil from '@/store/utils'
import { PrintOptionsModal, InvoicePrintOptionsModal, CopyQuoteModal, QuoteExportModal } from './components'
import QuoteService from './QuoteService'
import HttpStatus from '@/components/http-status'
import InsurerRoutes from '@/views/insurer/route-types'
import VehicleRoutes from '@/views/vehicle/route-types'
import { QuoteLabourMixin, QuoteOtherLabourMixin, QuotePartMixin, QuoteOpgMixin, QuoteMiscMixin, QuoteSubletMixin, QuoteNotificationMixin } from './mixins'
import { SmsModal } from '@/components/Sms'
import { SmsSendMessageModel, QuoteItemModel, QuoteItemPresentAsModel } from '@/classes/viewmodels'
import Guid from '@/components/Guid'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { VehicleService, CustomerService, InsurerService, QuoteTemplateService } from '@/services'
import { DeepDiff } from 'deep-diff'
import { PartsCheckService } from './services'
import { XmlViewerModal } from '@/components/XmlViewer'
import QuoteTemplateApplyModal from '@/components/QuoteTemplateModal/QuoteTemplateApplyModal'

export default {
  name: 'QuoteView',
  components: {
    QuoteSideMenu,
    AppDetailHeader,
    VueFab,
    UnsavedModal,
    PrintOptionsModal,
    InvoicePrintOptionsModal,
    SmsModal,
    CopyQuoteModal,
    ConfirmModal,
    QuoteExportModal,
    SaveConflictModal,
    XmlViewerModal,
    QuoteTemplateApplyModal
  },
  mixins: [
    QuoteValidation,
    StoreMixin,
    QuoteLabourMixin,
    QuoteOtherLabourMixin,
    QuotePartMixin,
    QuoteOpgMixin,
    QuoteMiscMixin,
    QuoteSubletMixin,
    QuoteNotificationMixin
  ],
  props: {
    returnUrl: {
      type: String,
      default: ''
    },
    invoiceType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      entity: null,
      entityMainQuote: null,
      isSkipSave: false,
      isSaveContinue: false,
      isUnsavedModalActive: false,
      isQuoteTemplateModalActive: false,
      isSaveConflictModalActive: false,
      toRoute: null,
      isPrintOptionsActive: false,
      isInvoicePrintOptionsActive: false,
      isSmsModalActive: false,
      isQuoteExportModalActive: false,
      smsDetails: null,
      isCopyQuoteModalActive: false,
      isConfirmModalActive: false,
      exportType: 'text',
      imageIds: [],
      fabActions: [],
      audanetSetting: null,
      pnetSetting: null,
      vehicle: null,
      customer: null,
      insurer: null,
      isCustomerCreateModifyModalActive: false,
      customerSaveAction: '',
      pricePartsReturnRoute: null,
      isHeaderButtonEnabled: true,
      isXmlViewerModalActive: false,
      messageType: '',
      quoteTemplateDisabled: true,
      templateQuoteItemCount: 0
    }
  },
  computed: {
    totalExGst() {
      if (this.entity) {
        return roundAwayFromZero(
          this.entity.rrTotal +
            this.entity.repairTotal +
            this.entity.rwaTotal +
            this.entity.paintTotal +
            this.entity.partTotal +
            this.entity.opgTotal +
            this.entity.fgTotal +
            this.entity.crushTotal +
            this.entity.cdTotal +
            this.entity.mechTotal +
            this.entity.miscTotal +
            this.entity.sublTotal
        )
      }
    },
    gst() {
      if (this.entity) {
        return roundAwayFromZero(this.totalExGst * (this.entity.gstRate / 100))
      }
    },
    totalIncGst() {
      return roundAwayFromZero(this.totalExGst + this.gst)
    },
    maxLineNumber() {
      const numbers = [
        Math.max(...this.entity.labours.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.parts.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.opgs.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.others.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.miscs.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.sublets.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0)
      ]
      return numbers
    },
    nextLineNumber() {
      // return this.entity.lines
      const numbers = [
        Math.max(...this.entity.labours.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.parts.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.opgs.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.others.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.miscs.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        Math.max(...this.entity.sublets.filter((i) => !(i.isNew && i.deleted)).map((i) => i.lineNumber), 0),
        this.entity.lines - 1
      ]
      return Math.max(...numbers) + 1
    },
    validateError() {
      this.$v.quoteHeaderGroup.$touch()
      return (
        (this.$v.entity.$anyError && this.$v.entity.$anyDirty) ||
        this.$v.vehicle.rego.$error ||
        this.$v.vehicle.model.$error ||
        this.$v.vehicle.bodyId.$error ||
        this.$v.vehicle.bodyId2.$error ||
        this.$v.vehicle.paintGroup.$error ||
        this.$v.customer.firstname.$error ||
        this.$v.customer.lastname.$error ||
        this.$v.customer.emails.$error ||
        this.$v.insurer.name.$error ||
        !this.$v.entity.jobEnd.validJobEnd ||
        !this.$v.entity.jobStart.validJobStart
      )
    },
    invoice() {
      return this.entity.invoices.find((i) => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.Quote)
    },
    excessInvoice() {
      return this.entity.invoices.find((i) => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.Excess)
    },
    ocInvoice() {
      return this.entity.invoices.find((i) => !i.isNew && !i.deleted && i.invoiceType === InvoiceTypes.OwnerContribution)
    },
    isInvoiced() {
      return this.entity && this.entity.invoices && this.entity.invoices.length > 0
    },
    isNtar() {
      if (this.entity) {
        return (
          this.entity.labourType === LabourTimeTypes.NTAR || this.entity.labourType === LabourTimeTypes.LTAR || this.entity.labourType === LabourTimeTypes.eMTA
        )
      }
    },
    isEmta() {
      return this.entity.labourType === LabourTimeTypes.eMTA
    },
    isRacq() {
      return this.entity.labourType === LabourTimeTypes.RACQ
    },
    deleteQuoteMessage() {
      if (this.entity.subQuoteNo > 0 || this.entity.subQuoteNos.length === 1) {
        return `${this.entity.prefix}${this.entity.quoteNo}${this.entity.suffix}`
      } else if (this.entity.subQuoteNo === 0 && this.entity.subQuoteNos.length > 1) {
        return `${this.entity.prefix}${this.entity.quoteNo}${this.entity.suffix} and all supplementary quotes`
      }
    },
    insurerDefaultEmail() {
      const defaultEmail = this.quoteInsurer.emails.find((e) => e.isDefault)
      if (defaultEmail) {
        return defaultEmail.address
      } else {
        return ''
      }
    },
    showNewAdditional() {
      if (this.entity && this.entity.isAssessmentEnabled) {
        return false
      } else {
        if (this.entity && this.entity.isPnet === true && this.entity.audatex === null && this.entity.pnet !== null) {
          if (this.entity.pnet.authorityNo !== null && this.entity.pnet.authorityNo !== '') return true
          else return false
        } else {
          return (
            this.entity &&
            ((!this.entity.isAudaNet && !this.entity.isAudaBridge) ||
              ((this.audanetSetting.property4 === '1' ||
                this.audanetSetting.property4.toLowerCase() === 'true' ||
                this.audanetSetting.property4.toLowerCase() === '') &&
                (this.entity.isAudaNet || this.entity.isAudaBridge)))
          )
        }
      }
    },
    // isAudanet() {
    //   return this.pnetSetting !== null && this.pnetSetting.property5 === '4.1'
    // },
    // isAudaBridge() {
    //   return this.entity.audatex !== null && this.entity.audatex.taskType === 'AudaBridge'
    // }
    uninterruptedView() {
      return this.$route.query.uninterruptedview === 'true'
    },
    canChangeReadOnly() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    },
    showQuoteActionButton() {
      return (
        this.entity &&
        !this.entity.isNew &&
        !this.snapshotDiff &&
        !this.uninterruptedView &&
        !(this.$route.name === QuoteRoutes.QuotePartsControl.name) &&
        !(this.$route.name === QuoteRoutes.QuotePriceParts.name)
      )
    },
    canViewPricePartsMsg() {
      return (
        this.$userInfo.isSupportUser &&
        this.entity &&
        this.entity.parts.filter((p) => p.partStatus == 'Imp' || p.partStatus == 'Exp' || p.partStatus == 'Pord').length > 0
      )
    }
  },
  watch: {
    'entity.labours': {
      handler: function (newVal, oldVal) {
        this.entity.paintTotal = roundAwayFromZero(this.paintTotal)
        this.entity.rrTotal = roundAwayFromZero(this.rrTotal)
        this.entity.repairTotal = roundAwayFromZero(this.repairTotal)
        this.entity.rwaTotal = roundAwayFromZero(this.rwaTotal)
        this.entity.labourTotal = roundAwayFromZero(this.labourTotal)
      },
      deep: true
    },
    'entity.others': {
      handler: function () {
        this.entity.cdTotal = roundAwayFromZero(this.cdTotal)
        this.entity.crushTotal = roundAwayFromZero(this.crushTotal)
        this.entity.mechTotal = roundAwayFromZero(this.mechTotal)
        this.entity.fgTotal = roundAwayFromZero(this.fgTotal)
      },
      deep: true
    },
    'entity.parts': {
      handler: function () {
        this.entity.partTotal = roundAwayFromZero(this.partTotal)
      },
      deep: true
    },
    'entity.opgs': {
      handler: function (newVal, oldVal) {
        this.entity.opgTotal = roundAwayFromZero(this.opgTotal)
        // Cannot do this. newVal & oldVal properties will be equal
        // if (newVal && newVal.length > 0 && oldVal && oldVal.length > 0) {
        //   let changed = newVal.filter(function(item, index) {
        //     console.log(item.opgCode, oldVal[index].opgCode)
        //     return item.opgCode !== oldVal[index].opgCode
        //   })
        //   console.log(changed)
        //   if (changed.length > 0) {
        //     console.log('opgCode changed')
        //   }
        // }
      },
      deep: true
    },
    'entity.miscs': {
      handler: function (newVal, oldVal) {
        this.entity.miscTotal = roundAwayFromZero(this.miscTotal)
      },
      deep: true
    },
    'entity.sublets': {
      handler: function (newVal, oldVal) {
        this.entity.sublTotal = roundAwayFromZero(this.subletTotal)
      },
      deep: true
    },
    labourTotal(newVal, oldVal) {
      // console.log(this.previousQuoteId, newVal, oldVal, (newVal && !oldVal), (!newVal && oldVal))
      if (this.previousQuoteId !== '' && this.previousQuoteId !== this.entity.quoteId) {
        return
      }
      // if ((newVal && !oldVal) || (!newVal && oldVal)) {
      //   console.log('labourTotal return')
      //   return
      // }
      if (!this.entity.readOnly && this.isRacq && !isNaN(oldVal)) {
        // RACQ Consumables percentage
        this.addUpdateRacqConsumable()
        this.showToast('RACQ consumables item updated', 'success', 'bottom')
      } else if (!this.entity.readOnly && this.isNtar && !isNaN(oldVal)) {
        this.addUpdateRepairPanelConsumable()
        this.addUpdatePaintConsumable()
        this.addUpdatePaintMaterial()
        this.addUpdateSetupColourMatch1()

        // just for paint items with OPG code
        // if (this.entity.labours.some((l) => l.opgCode !== null && l.opgCode !== '')) {
        this.addUpdateOpgPaintMaterials()
        this.addUpdateSetupColourMatch2()
        // }
        this.addUpdateM3PlusAllowances()

        this.showToast('NTAR consumables & paint material items updated', 'success', 'bottom')
      }
    },
    opgTotal(newVal, oldVal) {
      // console.log('opgTotal', newVal, oldVal)
      if (this.previousQuoteId !== '' && this.previousQuoteId !== this.entity.quoteId) {
        return
      }
      // if ((newVal && !oldVal) || (!newVal && oldVal)) {
      //   return
      // }
      if (!this.entity.readOnly && this.isRacq && !isNaN(oldVal)) {
        // RACQ Consumables percentage
        this.addUpdateRacqConsumable()
      } else if (!this.entity.readOnly && this.isNtar && !isNaN(oldVal)) {
        // console.log('opgTotal isNtar')
        this.addUpdatePaintConsumable()
        this.addUpdatePaintMaterial()
        this.addUpdateSetupColourMatch1()
        this.addUpdateOpgPaintMaterials()
        this.addUpdateSetupColourMatch2()
        this.addUpdateM3PlusAllowances()
        this.showToast('NTAR consumables & paint material items updated', 'success', 'bottom')
      }
    },
    weldHourTotal(newVal, oldVal) {
      if (this.previousQuoteId !== '' && this.previousQuoteId !== this.entity.quoteId) {
        return
      }
      // if ((newVal && !oldVal) || (!newVal && oldVal)) {
      //   return
      // }
      if (!this.entity.readOnly && (this.isEmta || this.isNtar) && !isNaN(oldVal)) {
        // eMTA R&R Welded Allowances
        this.addUpdateWeldAllowance()
      }
    },
    totalExGst(newVal) {
      if (this.entity && !this.readOnlyView) {
        this.entity.totalExGst = newVal
      }
    },
    totalIncGst(newVal) {
      if (this.entity && !this.readOnlyView) {
        this.entity.totalIncGst = newVal
      }
    },
    entity: {
      handler: function (newVal) {
        if (newVal) {
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      },
      deep: true
    },
    showNewAdditional(newVal) {
      if (newVal) {
        this.fabActions.splice(1, 0, {
          name: 'newAdditional',
          icon: 'plus-outline',
          color: '#c14747',
          tooltip: 'New Additional'
        })
      } else {
        this.fabActions = this.fabActions.filter((fabAction) => fabAction.name !== 'newAdditional')
      }
    },
    vehicle: {
      handler: function (newVal) {
        if (newVal) {
          this.saveVehicleSnapshot(this.vehicle)
        }
      },
      deep: true
    },
    customer: {
      handler: function (newVal) {
        if (newVal) {
          this.saveCustomerSnapshot(this.customer)
          this.vehicle.customer = {
            key: this.customer.id,
            value: this.customer.fullname
          }
        }
      },
      deep: true
    },
    insurer: {
      handler: function (newVal) {
        if (newVal) {
          this.saveInsurerSnapshot(this.insurer)
        }
      },
      deep: true
    },

    isNtar(newVal) {
      if (this.entity && this.isNtar) {
        this.getNtarLoadingItems()
        this.getNtarOtherLoadingItems()
        this.getNtarLoadingValues()
      }
    },
    // change price parts return route based on if the user is in QuotePartsControl or QuoteSelections
    $route(to, from) {
      console.log('QuoteView $route', to, from)

      if (to.name === QuoteRoutes.QuotePartsControl.name) {
        this.pricePartsReturnRoute = QuoteRoutes.QuotePartsControl.name
      } else if (to.name === QuoteRoutes.QuotePart.name) {
        this.pricePartsReturnRoute = QuoteRoutes.QuotePart.name
      }
    }
  },
  // errorCaptured(err, vm, info) {
  //   console.log(err)
  // },
  created() {
    this.getAudanetPnetSetting()
    this.setupFabButtons()
    this.getStandardLabourItems()
    this.getEntity()
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.quoteId)
    }
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntityReload, async () => {
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntityReloaded)
    })
    this.$eventHub.$on(`${EventHubTypes.ImageCountChanged}UpdateQuote`, () => {
      this.entity.modifiedDate = null
      this.save()
    })

    this.checkRoute()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntityReload)
    if (_isEmpty(this.returnRoute)) {
      this.setReadOnlyView(false)
    }
    this.$eventHub.$off(`${EventHubTypes.ImageCountChanged}UpdateQuote`)
  },
  methods: {
    checkRoute() {
      console.log('checking route')

      const partsControlEnabled = this.$company.setting.partsControlEnabled
      if (!partsControlEnabled && this.$route.name === QuoteRoutes.QuotePartsControl.name) {
        this.$router.push({ name: QuoteRoutes.QuoteHeader.name })

        this.$toast.open({
          message: 'Parts Control is not currently Available. Redirecting to Quote Header',
          type: 'is-danger'
        })
      }
    },

    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          console.log(this.snapshotDiff)
          if (this.snapshotDiff) {
            this.$unSavedModal.open({
              skipDisabled: true,
              onSkipSave: () => {},
              onCancel: () => {},
              onSaveContinue: async () => {
                await this.save()
                this.onHeaderButtonClicked(AppHeaderButtonTypes.Print)
              }
            })
            return
          }

          if (this.$route.name === QuoteRoutes.QuoteInvoice.name) {
            // Print invoice
            if (this.invoice) {
              // print Malaysia invoice
              if (this.$company.info.countryCode === 'MY') {
                this.printMyInvoice()
              } else {
                this.isInvoicePrintOptionsActive = true
              }
            } else {
              this.$toast.open({
                message: 'Nothing to print. Please create invoice first',
                type: 'is-danger'
              })
            }
          } else if (this.$route.name === QuoteRoutes.QuoteExcessInvoice.name) {
            // Print excess invoice
            if (this.excessInvoice) {
              this.printExcessInvoice()
            } else {
              this.$toast.open({
                message: 'Nothing to print. Please create excess invoice first',
                type: 'is-danger'
              })
            }
            if (!this.entity.excessWithGst && this.ocInvoice) {
              this.printOcInvoice()
            }
          } else {
            this.isPrintOptionsActive = true
          }
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
        case AppHeaderButtonTypes.Lock:
          this.toggleReadOnly()
          break
        case AppHeaderButtonTypes.PriceParts:
          this.viewPartsCheckRequest()
          break
        case AppHeaderButtonTypes.PreApplyQuoteTemplateCheck:
          this.checkBeforeQuoteTemplateApplication()
          break
        case AppHeaderButtonTypes.ApplyQuoteTemplate:
          this.applyQuoteTemplate()
          break
      }
    },
    checkBeforeQuoteTemplateApplication() {
      this.quoteTemplateDisabled = this.validateError
    },
    applyQuoteTemplate() {
      this.isQuoteTemplateModalActive = true
    },
    disableHeaderButtons(isDisable) {
      this.isHeaderButtonEnabled = !isDisable
    },
    async getEntity(reset = false) {
      this.$showSpinner()
      if (reset) {
        this.clearSessionStorage()
        this.clearSnapshots(this.$route.params.quoteId)
        this.clearHeaders()
        this.clearInvoiceState()
      }
      if (!this.currentSnapshot) {
        await this.getStoreItem(this.$route.params.quoteId)
      }
      this.entity = _cloneDeep(this.currentSnapshot)
      // Reset isCopyQuote to trigger unsaved changes modal
      if (this.entity.isCopyQuote) {
        const isCopyImages = this.entity.copyImageQuoteId !== Guid.empty()
        this.$toast.open({
          message: `Copy quote successful. Please remember to save quote.${isCopyImages ? ' Images will appear after saving quote.' : ''}`,
          type: 'is-success',
          duration: 5000
        })
        this.entity.isCopyQuote = false
      }
      if (this.entity.isNew) {
        await Promise.all([this.getNewVehicle(), this.getNewCustomer(), this.getNewInsurer()])
        this.vehicle = _cloneDeep(this.currentVehicleSnapshot)
        this.customer = _cloneDeep(this.currentCustomerSnapshot)
        this.insurer = _cloneDeep(this.currentInsurerSnapshot)
        this.entity.vehicleId = this.quoteVehicle.id
        this.entity.customerId = this.quoteCustomer.id
        this.entity.insurerId = this.quoteInsurer.insurerId
        this.entity.driveable = this.$company.setting.driveableDefault
        this.entity.jobStage = JobStageTypes.ToBeQuoted
        this.entity.quoteAssessments = []
      } else {
        const promises = []
        if (Guid.validGuid(this.entity.vehicleId)) {
          // Get and store vehicle in vuex store quotes/quoteVehicle
          promises.splice(promises.length, 1, this.getQuoteVehicle({ id: this.entity.vehicleId, refresh: true }))
          // this.getQuoteVehicle({ id: this.entity.vehicleId, refresh: reset })
        }
        if (Guid.validGuid(this.entity.customerId)) {
          // Get and store vehicle in vuex store quotes/quoteVehicle
          promises.splice(promises.length, 1, this.getQuoteCustomer({ id: this.entity.customerId, refresh: true }))
          // this.getQuoteCustomer({ id: this.entity.customerId, refresh: reset })
        }
        if (Guid.validGuid(this.entity.insurerId)) {
          promises.splice(promises.length, 1, this.getQuoteInsurer({ id: this.entity.insurerId, refresh: true }))
          // this.getQuoteInsurer({ id: this.entity.insurerId, refresh: false })
        }
        await Promise.all(promises)
        console.log('getEntity Promise.all')
        this.vehicle = _cloneDeep(this.currentVehicleSnapshot)
        this.customer = _cloneDeep(this.currentCustomerSnapshot)
        this.vehicle.customer = {
          key: this.customer.id,
          value: this.customer.fullname
        }
        this.insurer = _cloneDeep(this.currentInsurerSnapshot)
        console.log(`${this.$options.name} - ${this.vehicle.modifiedDate}`)
        console.log(`${this.$options.name} - ${this.customer.modifiedDate}`)
        console.log(`${this.$options.name} - ${this.insurer.modifiedDate}`)
      }
      if (this.isNtar || this.Emta) {
        this.getNtarLoadingItems()
        this.getNtarOtherLoadingItems()
        this.getNtarLoadingValues()
      }
      // this.setAudaNetLock(this.entity.isAudaNet && !this.$user.info.isSupportUser && !this.$user.info.isCustomerAdministrator)
      // if (!this.entity.isNew) {
      //   // Get and store vehicle in vuex store quotes/quoteVehicle
      //   this.getQuoteVehicle(this.entity.vehicleId)
      //   // Get and store vehicle in vuex store quotes/quoteCustomer
      //   this.getQuoteCustomer(this.entity.customerId)
      this.$resetSpinner()
    },
    async getMainQuoteEntity() {
      if (this.entity && this.entity.subQuoteNo > 0) {
        this.entityMainQuote = await QuoteService.getMainQuoteEntity(`${this.entity.prefix}${this.entity.quoteNo}${this.entity.suffix}`)
      } else {
        this.entityMainQuote = null
      }
    },
    async save(isDelete, showNoChangeMessage = true) {
      const title = 'Repairer Quote'
      if (this.validateError) {
        console.error('Validation errors', this.$v)
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        this.showItemsValidationErrors()
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (!this.vehicle.isNew && !this.customer.isNew && this.triggerIsNewOwnerPrompt()) {
          return false
        }
        await this.assignLineNumbers()
        if (this.entity.isNew) {
          await this.saveQuoteAssets(this.entity.isNew)
          response = await QuoteService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          await this.saveQuoteAssets()
          response = await QuoteService.putEntity(_cloneDeep(this.entity), this.snapshotDiff)
        } else if (showNoChangeMessage) {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }
        this.$hideSpinner()
        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, `New ${title} added`)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.entity.imageIds.length)

        const attachments = await QuoteService.getQuoteAttachments(this.entity.quoteId)
        let count = Object.values(attachments.data).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
        this.$eventHub.$emit(EventHubTypes.DocumentCountChanged, count)

        return true
      } catch (e) {
        this.$hideSpinner()
        console.log(e)
        if (e.response.request && e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError(title, e)
        }
        return false
      } finally {
        this.customerSaveAction = ''
        this.$hideSpinner()
      }
    },
    async saveQuoteAssets(isNew = false) {
      let promises = []
      if (this.customer.isNew) {
        // promises.splice(promises.length, 1, CustomerService.postCustomer(this.customer))
        await CustomerService.postCustomer(this.customer)
      } else if (this.snapshotDiffCustomer) {
        // promises.splice(promises.length, 1, CustomerService.putCustomer(this.customer, this.snapshotDiffCustomer))
        await CustomerService.putCustomer(this.customer, this.snapshotDiffCustomer)
      }

      if (this.vehicle.isNew) {
        promises.splice(promises.length, 1, VehicleService.postEntity(this.vehicle))
      } else if (this.snapshotDiffVehicle) {
        promises.splice(promises.length, 1, VehicleService.putEntity(this.vehicle, this.snapshotDiffVehicle))
      }

      if (this.insurer.isNew) {
        promises.splice(promises.length, 1, InsurerService.postInsurer(this.insurer))
      } else if (this.snapshotDiffInsurer) {
        promises.splice(promises.length, 1, InsurerService.putInsurer(this.insurer, this.snapshotDiffInsurer))
      }
      await Promise.all(promises)
    },
    triggerIsNewOwnerPrompt() {
      const customerDiff = DeepDiff.diff(this.quoteCustomer, this.currentCustomerSnapshot)
      if (customerDiff && !this.customerSaveAction) {
        const paths = customerDiff.map((d) => d.path).flat()
        let prompt = paths.includes('firstname') && paths.includes('lastname') && paths.includes('phones')
        if (!this.currentCustomerSnapshot.isPerson) {
          prompt = paths.includes('companyName')
        }
        if (prompt) {
          this.isCustomerCreateModifyModalActive = true
          return true
        }
        return false
      }
    },
    createVehicleCustomer() {
      this.isCustomerCreateModifyModalActive = false
      this.customerSaveAction = 'create'
      this.customer.isNew = true
      this.customer.id = this.$guid.newGuid()
      this.customer.addresses.forEach((a) => {
        a.isNew = true
        a.id = this.$guid.newGuid()
      })
      this.customer.contacts.forEach((a) => {
        a.isNew = true
        a.id = this.$guid.newGuid()
      })
      this.customer.emails.forEach((a) => {
        a.isNew = true
        a.id = this.$guid.newGuid()
      })
      this.customer.phones.forEach((a) => {
        a.isNew = true
        a.id = this.$guid.newGuid()
      })
      this.vehicle.customer.key = this.customer.id
      this.vehicle.customer.value = this.customer.fullname
      this.vehicle.isNew = true
      this.vehicle.id = this.$guid.newGuid()
      this.entity.customerId = this.customer.id
      this.entity.vehicleId = this.vehicle.id
      this.save()
    },
    modifyCustomer() {
      this.isCustomerCreateModifyModalActive = false
      this.customerSaveAction = 'modify'
      this.save()
    },
    delete() {
      this.entity.deleted = true
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.readOnlyView) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else if (this.returnUrl) {
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else {
          this.$router.push({
            name: QuoteRoutes.QuoteListView.name
          })
        }
      }
    },
    printExcessInvoice() {
      const params = {
        QuoteID: this.entity.quoteId,
        InvoiceNo: this.excessInvoice.invoiceNo,
        InvoiceDate: this.formatDateLocale(this.excessInvoice.invoiceDate, this.$userInfo.locale),
        InsurerID: this.entity.insurerId,
        CustomerID: this.excessInvoice.assetId, //this.entity.customerId,
        CompanyID: this.entity.companyId,
        ExcessAmount: `${this.excessInvoice.excessAmount}`,
        OwnerContributionAmt: `${this.excessInvoice.ownerContribution}`,
        ExtraCharge: `${this.excessInvoice.extraCharge}`,
        InvoiceID: this.excessInvoice.invoiceId
      }
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e}=${params[e]}`)
      //     .join('&')
      // )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.remark = `Excess Invoice ${this.excessInvoice.prefix}${this.excessInvoice.invoiceNo}${this.excessInvoice.suffix}`
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      emailer.subject = `Excess Invoice ${this.excessInvoice.prefix}${this.excessInvoice.invoiceNo}${this.excessInvoice.suffix}`
      emailer.reportName = `Excess-Invoice_${this.excessInvoice.prefix}${this.excessInvoice.invoiceNo}${this.excessInvoice.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.entity.excessWithGst ? 'rptExcessInvoiceWithGST' : 'rptExcessInvoice' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    printOcInvoice() {
      const params = {
        QuoteID: this.entity.quoteId,
        InvoiceNo: this.ocInvoice.invoiceNo,
        InvoiceDate: this.formatDateLocale(this.ocInvoice.invoiceDate, this.$userInfo.locale),
        InsurerID: this.entity.insurerId,
        CustomerID: this.entity.customerId,
        CompanyID: this.entity.companyId,
        OwnerContributionAmt: `${this.ocInvoice.ownerContribution}`,
        ExtraCharge: `${this.ocInvoice.extraCharge}`,
        InvoiceID: this.ocInvoice.invoiceId
      }
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e}=${params[e]}`)
      //     .join('&')
      // )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.remark = `Owner Contribution Invoice ${this.ocInvoice.prefix}${this.ocInvoice.invoiceNo}${this.ocInvoice.suffix}`
      emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      emailer.subject = `Owner Contribution Invoice ${this.ocInvoice.prefix}${this.ocInvoice.invoiceNo}${this.ocInvoice.suffix}`
      emailer.reportName = `Owner-Contribution-Invoice_${this.ocInvoice.prefix}${this.ocInvoice.invoiceNo}${this.ocInvoice.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'rptExcessInvoiceOwnerContribution' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    printMyInvoice() {
      let reportName = 'rptInvoiceMy'
      const params = {
        QuoteID: this.entity.quoteId,
        InvoiceNo: this.invoice ? `${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}/${this.invoice.invoiceType}` : '',
        InvoiceDate: this.formatDateLocale(this.invoice.invoiceDate, this.$userInfo.locale),
        InsurerID: this.entity.insurerId,
        CustomerID: this.entity.customerId,
        CompanyID: this.entity.companyId,
        InvoiceID: this.invoice.invoiceId,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.remark = `Invoice ${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}`
      emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      emailer.subject = `Invoice ${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}`
      emailer.reportName = `Invoice_${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: reportName },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    formatDateLocale(value, locale, format = '2-digit') {
      const date = new Date(Date.parse(`${value}`))
      const options = { year: 'numeric', month: format, day: '2-digit' }
      if (Intl) {
        return Intl.DateTimeFormat(locale, options).format(date)
      } else {
        return date.toLocaleDateString(locale, options)
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.isPrintOptionsActive = false
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|quote|returnUrl`, this.returnUrl)
      }
      if (this.invoiceType) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|quote|invoiceType`, this.invoiceType)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|quote|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|quote|invoiceType`)
    },
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        invoiceType: this.invoiceType
      })
      this.$router.replace({
        name:
          this.invoiceType === InvoiceTypes.Quote || this.invoiceType === InvoiceTypes.Gst
            ? QuoteRoutes.QuoteInvoice.name
            : QuoteRoutes.QuoteExcessInvoice.name,
        params: { quoteId: id },
        meta: newMeta
      })
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    async newAdditional() {
      const canStartAdditional = !this.entity.orm
        ? true
        : this.entity.invoices.some(
            (i) =>
              i.invoiceType === InvoiceTypes.Quote &&
              !i.deleted &&
              (this.entity.orm.quoteStatusId === OrmQuoteStatusTypes.QuoteInvoiceSubmitted || OrmQuoteStatusTypes.PaymentAuthorised)
          )
      if (canStartAdditional) {
        this.setReturnRoute(this.$route)
        this.addStoreSupplementaryItem(this.entity.quoteNo)
      } else {
        this.$notification.openNotificationWithType(
          'danger',
          'Repairer Quote',
          //eslint-disable-next-line
          "Variation for ORM quote should be done on main quote if it's not already invoiced",
          6000
        )
      }
    },
    exportQuote() {
      this.isQuoteExportModalActive = true
    },
    async closeQuoteExportModal() {
      this.isQuoteExportModalActive = false
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntityReloaded)
    },
    async showCopyQuoteModal() {
      this.imageIds = await QuoteService.getImageIds(this.entity.quoteId)
      this.isCopyQuoteModalActive = true
    },
    copyQuote(destinationId, isCopyImages, isOverwrite) {
      // console.log('copy', destinationId, isCopyImages)
      this.isCopyQuoteModalActive = false
      this.copyStoreQuote({ sourceId: this.entity.quoteId, destinationId: destinationId, isCopyImages: isCopyImages, isOverwrite: isOverwrite })
    },
    sendSms() {
      var jobStart = new Date(this.entity.jobStart).toLocaleDateString(this.$userInfo.locale)
      var mobile = ''

      mobile = this.quoteCustomer.phones.find((a) => a.type === PhoneTypes.Mobile.toString())

      if (mobile == undefined) {
        mobile = ''
      } else {
        mobile = mobile.no
      }

      this.smsDetails = new SmsSendMessageModel(this.entity.quoteId, this.quoteCustomer.fullname, this.quoteVehicle.rego, jobStart, this.entity.excess, mobile)
      this.isSmsModalActive = true
    },
    closeSmsModal() {
      this.isSmsModalActive = false
    },
    changeQuote(subQuote) {
      // console.log(subQuote.key)
      this.$router.push({
        name: this.$route.name,
        params: { quoteId: subQuote.value }
      })
    },
    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        if (this.entity.subQuoteNo === 0 && this.entity.subQuoteNos.length > 1) {
          // Delete all supplementary quotes
          const vm = this
          const quoteIds = this.entity.subQuoteNos.filter((q) => q.key !== vm.entity.subQuoteNo).map((q) => q.value)
          QuoteService.deleteMultipleEntities(quoteIds)
        }
        this.entity.deleted = true
        this.save(true)
      }
    },
    async getAudanetPnetSetting() {
      this.audanetSetting = await QuoteService.getExternalSetting('audatex')
      this.pnetSetting = await QuoteService.getExternalSetting('pnet')
    },
    deleteItem(item, items) {
      if (item.isNew) {
        const itemIndex = items
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          items.splice(itemIndex, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
      }
    },
    onOpgCodeChanged() {
      if (!this.entity.readOnly && this.isRacq) {
        // RACQ Consumables percentage
        this.addUpdateRacqConsumable()
      } else if (!this.entity.readOnly && this.isNtar) {
        // console.log('opgTotal isNtar')
        this.addUpdatePaintConsumable()
        this.addUpdatePaintMaterial()
        this.addUpdateM3PlusAllowances()
        this.addUpdateSetupColourMatch1()
        this.addUpdateOpgPaintMaterials()
        this.addUpdateSetupColourMatch2()
      }
    },
    addUpdateRacqConsumable() {
      const total = this.labourTotal + this.opgTotal
      const racqConsumable = this.quoteInsurer.insurerConsumables.find((c) => c.typeID === 4)
      const percentage = racqConsumable.value
      const consumableAmount = roundAwayFromZero((total * percentage) / 100)
      // console.log('RACQ Consumable =', consumableAmount)
      let miscItem = this.entity.miscs.find((i) => i.itemNo === racqConsumable.itemNo && !i.deleted && !i.deleteOtherItem)
      if (consumableAmount > 0) {
        if (!miscItem) {
          miscItem = new QuoteItemModel(this.quoteId, racqConsumable.itemNo, 'Total Consumables', ItemCategoryTypes.MISC)
          miscItem.lineNumber = this.nextLineNumber
          miscItem.value = consumableAmount
          miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
          this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          // this.showToast('RACQ consumable item added', 'success', 'bottom')
        } else {
          miscItem.value = consumableAmount
          // this.showToast('RACQ consumable item updated', 'success', 'bottom')
        }
      } else if (miscItem) {
        this.deleteItem(miscItem, this.entity.miscs)
        // this.showToast('RACQ consumable item delete', 'danger', 'bottom')
      }
    },
    addUpdateWeldAllowance() {
      let weldAllowance = this.quoteInsurer.insurerConsumables.find((c) => c.typeID === 5)
      if (this.isNtar) {
        const standardItem = this.standardLabourItems.find((i) => i.itemNo === weldAllowance.itemNo)
        weldAllowance.description = standardItem.itemDesc
      }
      let miscItem = this.entity.miscs.find((i) => i.itemNo === weldAllowance.itemNo && !i.deleted && !i.deleteOtherItem)
      const weldAllowanceAmount = roundAwayFromZero(this.weldHourTotal * weldAllowance.value)
      if (weldAllowanceAmount > 0) {
        if (!miscItem) {
          miscItem = new QuoteItemModel(this.quoteId, weldAllowance.itemNo, weldAllowance.description, ItemCategoryTypes.MISC)
          miscItem.lineNumber = this.nextLineNumber
          miscItem.value = weldAllowanceAmount
          miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
          this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          // this.showToast('eMTA Weld Allowance item added', 'success', 'bottom')
        } else {
          miscItem.value = weldAllowanceAmount
          // this.showToast('eMTA Weld Allowance item updated', 'success', 'bottom')
        }
      } else if (miscItem) {
        this.deleteItem(miscItem, this.entity.miscs)
        // this.showToast('eMTA Weld Allowance item deleted', 'danger', 'bottom')
      }
    },
    addUpdateRepairPanelConsumable() {
      // NTAR Panel Consumable. Per repair hour. ItemNo 9991
      const rtrmVersion = this.entity.verRtrm
        ? this.entity.verRtrm
        : Math.max.apply(
            Math,
            this.quoteInsurer.insurerConsumables.map(function (c) {
              return c.versionNo
            })
          )
      const repairConsumable = this.quoteInsurer.insurerConsumables.find((c) => c.typeID === 1 && c.versionNo === rtrmVersion)
      const hours = this.entity.labours
        .filter((i) => !i.deleted && !i.reportOnly && (i.itemType === ItemCategoryTypes.REP || i.itemType === ItemCategoryTypes.RWA))
        .reduce(function (total, item) {
          return total + item.hourValue
        }, 0)
      const consumableAmount = roundAwayFromZero(hours * repairConsumable.value)
      let miscItem = this.entity.miscs.find((i) => i.itemNo === repairConsumable.itemNo && !i.deleted && !i.deleteOtherItem)

      console.log('Update Repair Consumable -> ', 'Version : ', rtrmVersion, 'Consumable Amount: ', consumableAmount)

      if (consumableAmount > 0) {
        if (!miscItem) {
          miscItem = new QuoteItemModel(this.quoteId, repairConsumable.itemNo, repairConsumable.description, ItemCategoryTypes.MISC)
          miscItem.lineNumber = this.nextLineNumber
          miscItem.value = consumableAmount
          miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
          this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          // this.showToast('Panel Consumables item added', 'success', 'bottom')
        } else {
          miscItem.value = consumableAmount
          // this.showToast('Panel Consumables item updated', 'success', 'bottom')
        }
      } else if (miscItem) {
        this.deleteItem(miscItem, this.entity.miscs)
        // this.showToast('Panel Consumables item deleted', 'danger', 'bottom')
      }
    },
    addUpdatePaintConsumable() {
      // NTAR Paint Consumable. Per paing hour. ItemNo 9992
      const rtrmVersion = this.entity.verRtrm
        ? this.entity.verRtrm
        : Math.max.apply(
            Math,
            this.quoteInsurer.insurerConsumables.map(function (c) {
              return c.versionNo
            })
          )
      const paintConsumable = this.quoteInsurer.insurerConsumables.find((c) => c.typeID === 3 && c.versionNo === rtrmVersion)
      const hours = this.entity.labours
        // eslint-disable-next-line no-unexpected-multiline
        .filter(
          (i) =>
            !i.deleted &&
            !i.reportOnly &&
            (i.itemType === ItemCategoryTypes.PAINT || i.itemType === ItemCategoryTypes.OPG) &&
            i.opgCode === this.vehicle.paintGroup
        )
        .reduce(function (total, item) {
          return total + item.hourValue
        }, 0)
      const amount = roundAwayFromZero(hours * paintConsumable.value)
      let miscItem = this.entity.miscs.find((i) => i.itemNo === paintConsumable.itemNo && !i.deleted && !i.deleteOtherItem)

      console.log('Update Paint Consumable -> ', 'Version : ', rtrmVersion, 'Consumable Amount: ', amount)

      if (amount > 0) {
        const presentAsItem = new QuoteItemPresentAsModel('PAINM', '', Math.max(100, 0))
        if (!miscItem) {
          miscItem = new QuoteItemModel(this.quoteId, paintConsumable.itemNo, paintConsumable.description, ItemCategoryTypes.MISC)
          miscItem.lineNumber = this.nextLineNumber
          miscItem.value = amount
          miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
          this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          // this.showToast('Paint Consumables item added', 'success', 'bottom')
          miscItem.presentAs.push(presentAsItem)
        } else {
          miscItem.value = amount
          // this.showToast('Paint Consumables item updated', 'success', 'bottom')
          if (miscItem.presentAs === null) {
            miscItem.presentAs = []
            miscItem.presentAs.push(presentAsItem)
          }
        }
      } else if (miscItem) {
        this.deleteItem(miscItem, this.entity.miscs)
        // this.showToast('Paint Consumables item deleted', 'danger', 'bottom')
      }
    },
    addUpdatePaintMaterial() {
      // NTAR Paint Materia. Per paint hour
      const rtrmVersion = this.entity.verRtrm
        ? this.entity.verRtrm
        : Math.max.apply(
            Math,
            this.quoteInsurer.insurerConsumables.map(function (c) {
              return c.versionNo
            })
          )

      const paintMaterial = this.quoteInsurer.insurerPaintMaterials.find((c) => c.type === this.quoteVehicle.paintGroup && c.versionNo === rtrmVersion)
      const hours = this.entity.labours
        // eslint-disable-next-line no-unexpected-multiline
        .filter((i) => !i.deleted && !i.reportOnly && i.itemType === ItemCategoryTypes.PAINT && i.opgCode === this.vehicle.paintGroup)
        .reduce(function (total, item) {
          return total + item.hourValue
        }, 0)
      const amount = roundAwayFromZero(hours * paintMaterial.value)
      console.log('addUpdatePaintMaterial', hours, amount)
      let miscItem = this.entity.miscs.find((i) => i.itemNo === paintMaterial.itemNo && !i.deleted && !i.deleteOtherItem)

      console.log('Update Paint Material -> ', 'Version : ', rtrmVersion, 'Consumable Amount: ', amount)

      if (amount > 0) {
        const presentAsItem = new QuoteItemPresentAsModel('PAINM', '', Math.max(100, 0))
        if (!miscItem) {
          miscItem = new QuoteItemModel(this.quoteId, paintMaterial.itemNo, `PAINT MATERIALS ${paintMaterial.type}`, ItemCategoryTypes.MISC)
          miscItem.lineNumber = this.nextLineNumber
          miscItem.value = amount
          miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
          this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          // this.showToast('Paint Material item added', 'success', 'bottom')
          miscItem.presentAs.push(presentAsItem)
        } else {
          miscItem.value = amount
          if (miscItem.presentAs === null) {
            miscItem.presentAs = []
            miscItem.presentAs.push(presentAsItem)
          }
          // this.showToast('Paint Material item updated', 'success', 'bottom')
        }
      } else if (miscItem) {
        this.deleteItem(miscItem, this.entity.miscs)
        // this.showToast('Paint Material item deleted', 'danger', 'bottom')
      }
    },
    addUpdateM3PlusAllowances() {
      if (this.quoteVehicle.paintGroup === PaintGroupTypes.M3 && this.quoteVehicle.paintGroupPlus && this.isNtar) {
        const rtrmVersion = this.entity.verRtrm
          ? this.entity.verRtrm
          : Math.max.apply(
              Math,
              this.quoteInsurer.insurerConsumables.map(function (c) {
                return c.versionNo
              })
            )

        const m3plus = this.quoteInsurer.insurerPaintMaterials.find((c) => c.type === 'M3Plus' && c.versionNo === rtrmVersion)

        // M3+ Allowances. Per paint hour
        const allowanceRate = m3plus ? m3plus.value : 0.32
        const itemNo = m3plus ? m3plus.itemNo : '9970'

        const paintHours = this.entity.labours
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && i.itemType === ItemCategoryTypes.PAINT && i.opgCode === this.vehicle.paintGroup)
          .reduce(function (total, item) {
            return total + item.hourValue
          }, 0)
        const paintRate = this.entity.rates[0]
        const allowanceHours = allowanceRate * paintHours
        const allowance = roundAwayFromZero(allowanceHours * paintRate.rate)

        let miscItem = this.entity.miscs?.find((i) => i.itemNo === itemNo && !i.deleted && !i.deleteOtherItem)
        console.log(this.$options.name, paintRate, paintHours, allowance, allowanceRate)

        console.log('Update M3Plus Material -> ', 'Version : ', rtrmVersion, 'Consumable Amount: ', allowance, 'misc item', miscItem)

        if (!miscItem && allowance > 0) {
          miscItem = new QuoteItemModel(this.entity.quoteId, itemNo, 'M3+ Allowances', ItemCategoryTypes.MISC)
          miscItem.lineNumber = this.nextLineNumber
          miscItem.value = allowance
          miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
          this.$nextTick(() => {
            this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          })
        } else if (allowance > 0) {
          miscItem.value = allowance
        } else if (miscItem && allowance === 0) {
          this.deleteItem(miscItem, this.entity.miscs)
        }
      }
    },
    addUpdateSetupColourMatch1() {
      const standardItem = this.standardLabourItems.find((i) => i.itemNo === '9998')
      const hours = this.entity.labours
        // eslint-disable-next-line no-unexpected-multiline
        .filter((i) => !i.deleted && !i.reportOnly && i.itemType === ItemCategoryTypes.PAINT && i.opgCode === this.vehicle.paintGroup)
        .reduce(function (total, item) {
          return total + item.hourValue
        }, 0)
      let miscItem = this.entity.miscs.find((i) => i.itemNo === standardItem.itemNo && !i.deleted && !i.deleteOtherItem)

      const isOtherPaintGroup = this.entity.labours.some(
        (i) => !i.deleted && !i.reportOnly && i.itemType === ItemCategoryTypes.PAINT && i.opgCode !== this.vehicle.paintGroup
      )

      // only show setup colour match 1 if there are paint hours and no other paint group
      if (hours > 0 && !isOtherPaintGroup) {
        const setupHour = this.quoteInsurer.insurerSpecialRates.find((i) => i.itemNo === '9998')
        const value = setupHour ? setupHour.value : standardItem.rrHour
        if (!miscItem) {
          miscItem = new QuoteItemModel(this.quoteId, standardItem.itemNo, standardItem.itemDesc, ItemCategoryTypes.MISC)
          miscItem.lineNumber = this.nextLineNumber
          miscItem.value = roundAwayFromZero(value * this.entity.shopRate)
          miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
          this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          // this.showToast('Setup Colour Match 1 item added', 'success', 'bottom')
        } else {
          miscItem.value = roundAwayFromZero(value * this.entity.shopRate)
          // this.showToast('Setup Colour Match 1 item updated', 'success', 'bottom')
        }
      } else if (miscItem) {
        this.deleteItem(miscItem, this.entity.miscs)
        // this.showToast('Setup Colour Match 1 item deleted', 'danger', 'bottom')
      }
    },
    addUpdateOpgPaintMaterials() {
      const paintMaterials = this.quoteInsurer.insurerPaintMaterials
      for (var i = 0; i < paintMaterials.length; i++) {
        const paintMaterial = paintMaterials[i]
        // console.log(paintMaterial.itemNo)
        const paintLayer = paintMaterial.type
        const amount = this.entity.labours
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && i.opgCode === paintLayer && i.opgCode !== this.vehicle.paintGroup)
          .reduce(function (total, item) {
            return total + item.hourValue * paintMaterial.value
          }, 0)

        let miscItem = this.entity.miscs.find((i) => i.itemNo === paintMaterial.itemNo && !i.deleted && !i.deleteOtherItem)
        if (amount > 0) {
          if (!miscItem) {
            miscItem = new QuoteItemModel(this.quoteId, paintMaterial.itemNo, `PAINT MATERIALS ${paintMaterial.type}`, ItemCategoryTypes.MISC)
            miscItem.lineNumber = this.nextLineNumber
            miscItem.value = amount
            miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
            this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          } else {
            if (paintMaterial.type === this.quoteVehicle.paintGroup) {
              miscItem.value += amount
            } else {
              miscItem.value = amount
            }
          }
        } else if (miscItem && paintMaterial.type !== this.quoteVehicle.paintGroup) {
          this.deleteItem(miscItem, this.entity.miscs)
        }
      }
    },
    addUpdateSetupColourMatch2() {
      const standardItem = this.standardLabourItems.find((i) => i.itemNo === '9999')
      let miscItem = this.entity.miscs.find((i) => i.itemNo === standardItem.itemNo && !i.deleted && !i.deleteOtherItem)
      const hours = this.entity.labours
        // eslint-disable-next-line no-unexpected-multiline
        .filter((i) => !i.deleted && !i.reportOnly && i.opgCode !== this.vehicle.paintGroup)
        .reduce(function (total, item) {
          return total + item.hourValue
        }, 0)

      if (this.entity.labours.length > 0 && hours > 0) {
        const setupHour = this.quoteInsurer.insurerSpecialRates.find((i) => i.itemNo === '9999')
        const value = setupHour ? setupHour.value : standardItem.rrHour

        if (!miscItem) {
          miscItem = new QuoteItemModel(this.quoteId, standardItem.itemNo, standardItem.itemDesc, ItemCategoryTypes.MISC)
          miscItem.lineNumber = this.nextLineNumber
          miscItem.value = roundAwayFromZero(value * this.entity.shopRate)
          miscItem.sortNo = this.entity.miscs.length ? Math.max(...this.entity.miscs.map((i) => i.sortNo)) + 1 : 1
          this.entity.miscs.splice(this.entity.miscs.length, 1, miscItem)
          // this.showToast('Setup Colour Match 2 item added', 'success', 'bottom')
        } else {
          miscItem.value = roundAwayFromZero(value * this.entity.shopRate)
          // this.showToast('Setup Colour Match 2 item updated', 'success', 'bottom')
        }
      } else if (miscItem) {
        this.deleteItem(miscItem, this.entity.miscs)
        // this.showToast(`Setup Colour Match 2 item deleted`, 'danger', 'bottom')
      }
    },
    async setupFabButtons() {
      this.fabActions.splice(this.fabActions.length, 1, {
        name: 'copyQuote',
        icon: 'content-copy',
        color: '#a8a145',
        tooltip: 'Copy Quote'
      })
      // this.fabActions.splice(this.fabActions.length, 1, {
      //   name: 'newAdditional',
      //   icon: 'plus-outline',
      //   color: '#c14747',
      //   tooltip: 'New Additional'
      // })
      this.fabActions.splice(this.fabActions.length, 1, {
        name: 'exportQuote',
        icon: 'export',
        color: '#2c5ba5',
        tooltip: 'Export'
      })
    },
    showItemsValidationErrors() {
      if (this.$v.entity.labours.$invalid) {
        this.$v.entity.labours.$touch()
      }
      if (this.$v.entity.others.$invalid) {
        this.$v.entity.others.$touch()
      }
      if (this.$v.entity.parts.$invalid) {
        this.$v.entity.parts.$touch()
      }
      if (this.$v.entity.opgs.$invalid) {
        this.$v.entity.opgs.$touch()
      }
      if (this.$v.entity.miscs.$invalid) {
        this.$v.entity.miscs.$touch()
      }
      if (this.$v.entity.sublets.$invalid) {
        this.$v.entity.sublets.$touch()
      }
      if (this.$v.detailGroup.$invalid) {
        this.$v.detailGroup.$touch()
      }
      if (this.$v.vehicleCustomerGroup.$invalid) {
        this.$v.vehicleCustomerGroup.$touch()
      }
      if (this.$v.estimateJobStartEnd.$invalid) {
        this.$v.estimateJobStartEnd.$touch()
      }
    },
    async conflictMerge() {
      this.entity.mergeOnConflict = true
      this.isSaveConflictModalActive = false
      await this.save()
    },
    async conflictReload() {
      this.isSaveConflictModalActive = false
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntitySaved)
      // this.$nextTick(() => {
      //   this.$eventHub.$emit(EventHubTypes.EntitySaved)
      // })
    },
    async selectVehicle(vehicleId, customerId) {
      await Promise.all([this.getQuoteVehicle({ id: vehicleId, refresh: true }), this.getQuoteCustomer({ id: customerId, refresh: true })])
      this.vehicle = _cloneDeep(this.currentVehicleSnapshot)
      this.customer = _cloneDeep(this.currentCustomerSnapshot)
      this.$nextTick(() => {
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
      })
    },
    async selectCustomer(id) {
      await this.getQuoteCustomer({ id: id, refresh: true })
      this.customer = _cloneDeep(this.currentCustomerSnapshot)
      this.entity.customerId = this.customer.id
      this.$nextTick(() => {
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
      })
    },
    async selectInsurer(id) {
      console.log(`Insurer, ${id}, ${this.entity.insurerId}`)
      this.entity.insurerId = id
      await this.getQuoteInsurer({ id: id, refresh: true })
      this.insurer = _cloneDeep(this.currentInsurerSnapshot)
      const isItemsEmpty =
        this.entity.labours.length === 0 &&
        this.entity.parts.length === 0 &&
        this.entity.others.length === 0 &&
        this.entity.opgs.length === 0 &&
        this.entity.miscs.length === 0 &&
        this.entity.sublets.length === 0

      if (isItemsEmpty || this.entity.isNew) {
        this.entity.newMarkup = this.insurer.newMarkup
        this.entity.usedMarkup = this.insurer.usedMarkup
        this.entity.recoreMarkup = this.insurer.recoreMarkup
        this.entity.afterMarketMarkup = this.insurer.afterMarketMarkup
        this.entity.exchangeMarkup = this.insurer.exchangeMarkup
        this.entity.reconditionedMarkup = this.insurer.reconditionedMarkup
        this.entity.shopRate = this.insurer.shopRate
        this.entity.labourType = this.insurer.labourType
        this.entity.excessWithGst = this.insurer.excessGST
        this.entity.quotingMethod = this.insurer.quotingMethod
        this.entity.verRtrm = Math.max.apply(
          Math,
          this.insurer.insurerConsumables.map(function (c) {
            return c.versionNo
          })
        )
        // Update quote rates
        this.addInsurerLabourRates(this.insurer)
        // Add or update automatic items to quote
        this.addInsurerAutoItems(this.insurer)
        // Add or update template items to quote
        await this.addInsurerTemplateItems(this.insurer)
      }
      this.$nextTick(() => {
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
      })
    },
    addInsurerLabourRates(insurer) {
      const vm = this
      const isInsurerNtar =
        insurer.labourType === LabourTimeTypes.NTAR || insurer.labourType === LabourTimeTypes.LTAR || insurer.labourType === LabourTimeTypes.eMTA
      insurer.insurerLabourRates.forEach(function (ir) {
        const index = vm.entity.rates.findIndex((qr) => qr.labourCodeId === ir.labourCode)
        if (index > -1) {
          vm.entity.rates[index].rate = isInsurerNtar ? insurer.shopRate : ir.rate
        } else {
          const labourType = vm.$labourTypes.find((t) => t.labourTypeCode === ir.labourType)
          const newRate = {
            rate: isInsurerNtar ? insurer.shopRate : ir.rate,
            quoteVersion: 0,
            quoteId: vm.entity.quoteId,
            labourCodeId: ir.labourCode,
            labourTypeId: labourType.labourTypeId,
            deleted: false,
            createdBy: '',
            createdDate: '',
            modifiedBy: '',
            modifiedDate: '',
            isNew: true,
            isDeleted: false
          }
          vm.entity.rates.splice(vm.entity.rates.length, 1, newRate)
        }
      })
    },
    addInsurerAutoItems(insurer) {
      if (insurer.insurerAutoItems && insurer.insurerAutoItems.length > 0) {
        const vm = this
        let lineNumber = this.nextLineNumber
        insurer.insurerAutoItems.forEach(function (autoItem) {
          let currentItem = vm.entity.miscs.find((i) => i.itemDesc === autoItem.itemDesc && i.itemNo === autoItem.itemNo)
          if (!currentItem) {
            let newItem = new QuoteItemModel(vm.entity.quoteId, autoItem.itemNo, autoItem.itemDesc, ItemCategoryTypes.MISC)
            newItem.value = autoItem.price
            newItem.reportOnly = autoItem.reportOnly
            newItem.sortNo = vm.entity.miscs.length ? Math.max(...vm.entity.miscs.map((i) => i.sortNo)) + 1 : 1
            newItem.lineNumber = lineNumber
            vm.entity.miscs.splice(vm.entity.miscs.length, 1, newItem)
            lineNumber++
            vm.entity.lines++
          } else {
            currentItem.value = autoItem.price
            currentItem.reportOnly = autoItem.reportOnly
          }
        })
      }
    },
    async addInsurerTemplateItems(insurer) {
      if (Guid.validGuid(insurer.templateId)) {
        const templateItems = await QuoteTemplateService.getQuoteTemplateItems(insurer.templateId)
        if (templateItems && templateItems.length > 0) {
          const vm = this
          templateItems.forEach(function (templateItem) {
            let currentItem = vm.entity.miscs.find(
              (i) => i.itemDesc === templateItem.itemDesc && i.itemNo === templateItem.itemNo && i.itemType === templateItem.itemType
            )
            if (!currentItem) {
              let newItem = new QuoteItemModel(vm.entity.quoteId, templateItem.itemNo, templateItem.itemDesc, templateItem.itemType)
              newItem.value = templateItem.value
              newItem.reportOnly = templateItem.reportOnly
              newItem.sortNo = templateItem.sortNo
              // let sortNo
              switch (templateItem.itemType) {
                case ItemCategoryTypes.RR:
                case ItemCategoryTypes.REP:
                case ItemCategoryTypes.RWA:
                  newItem.rate = vm.getLabourRate(templateItem.itemType)
                  vm.entity.labours.splice(vm.entity.labours.length, 1, newItem)
                  break
                case ItemCategoryTypes.PAINT:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.entity.labours.splice(vm.entity.labours.length, 1, newItem)
                  break
                case ItemCategoryTypes.OPG:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.entity.opgs.splice(vm.entity.opgs.length, 1, newItem)
                  break
                case ItemCategoryTypes.MECH:
                case ItemCategoryTypes.CD:
                case ItemCategoryTypes.CRUSH:
                case ItemCategoryTypes.FIBER:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.entity.others.splice(vm.entity.others.length, 1, newItem)
                  break
                case ItemCategoryTypes.PART:
                  vm.entity.parts.splice(vm.entity.parts.length, 1, newItem)
                  break
                case ItemCategoryTypes.MISC:
                  vm.entity.miscs.splice(vm.entity.miscs.length, 1, newItem)
                  break
                case ItemCategoryTypes.SUBL:
                  vm.entity.sublets.splice(vm.entity.sublets.length, 1, newItem)
                  break
              }
              // newItem.sortNo = templateItem.sortNo // || sortNo
            } else {
              currentItem.value = templateItem.value
              currentItem.itemType = templateItem.itemType
              currentItem.reportOnly = templateItem.reportOnly
              currentItem.sortNo = templateItem.sortNo
            }
          })
        }
      }
    },
    getLabourRate(code) {
      if (!this.entity.rates) {
        return {}
      }
      let rate = this.isNtar ? this.entity.rates[0] : this.entity.rates.find((i) => i.labourCodeId === code)
      return !rate ? 0 : rate.rate
    },
    async assignLineNumbers() {
      let maxLineNo = this.entity.subQuoteNo * 1000
      if (!this.entity.isNew) {
        maxLineNo = await QuoteService.getMaxLineNumber(this.entity.quoteId)
        if (maxLineNo === 0) maxLineNo = this.entity.subQuoteNo * 1000
      }
      this.entity.labours.forEach((i) => {
        if (i.isNew) {
          maxLineNo++
          i.lineNumber = maxLineNo
        }
      })
      this.entity.parts.forEach((i) => {
        if (i.isNew) {
          maxLineNo++
          i.lineNumber = maxLineNo
        }
      })
      this.entity.opgs.forEach((i) => {
        if (i.isNew) {
          maxLineNo++
          i.lineNumber = maxLineNo
        }
      })
      this.entity.others.forEach((i) => {
        if (i.isNew) {
          maxLineNo++
          i.lineNumber = maxLineNo
        }
      })
      this.entity.miscs.forEach((i) => {
        if (i.isNew) {
          maxLineNo++
          i.lineNumber = maxLineNo
        }
      })
      this.entity.sublets.forEach((i) => {
        if (i.isNew) {
          maxLineNo++
          i.lineNumber = maxLineNo
        }
      })
      this.entity.lines = maxLineNo + 1
    },
    toggleReadOnly() {
      if (this.canChangeReadOnly) {
        this.entity.readOnly = !this.entity.readOnly
        this.entity.readOnlyStatuses.splice(this.entity.subQuoteNo, 1, this.entity.readOnly)
      }
    },
    async authoriseQuote(quoteId, assessment) {
      this.$showSpinner()
      await QuoteService.authoriseAssessment(quoteId, assessment)
      await this.getEntity(true)
    },
    async requestAuhtority(quoteId, assessment) {
      this.$showSpinner()
      await QuoteService.requestAuthorityAssessment(quoteId, assessment)
      await this.getEntity(true)
    },
    async revertAuthority(quoteId) {
      this.$showSpinner()
      await QuoteService.revertToAuthorised(quoteId)
      await this.getEntity(true)
    },
    async cancelAuthority(quoteId, merge) {
      this.$showSpinner()
      await QuoteService.cancelLastAuthority(quoteId, merge)
      await this.getEntity(true)
    },
    async cancelRequest(quoteId, merge) {
      this.$showSpinner()
      await QuoteService.cancelLastRequest(quoteId, merge)
      await this.getEntity(true)
    },
    async downloadPartsCheckPrice() {
      if (!this.snapshotDiff) {
        this.$showSpinner('Fetching prices...')
        try {
          const clonedEntity = _cloneDeep(this.entity)
          const countryCode = this.$company.info.countryCode
          const priceUpdate = await PartsCheckService.getPartsCheckPrices(clonedEntity.quoteId)

          if (priceUpdate.parts.length > 0) {
            let nextLineNumber = this.nextLineNumber
            priceUpdate.parts.forEach(function (partsCheckPart) {
              let part
              if (partsCheckPart.isNew) {
                part = new QuoteItemModel(clonedEntity.quoteId, partsCheckPart.itemNo, partsCheckPart.itemDesc, ItemCategoryTypes.PART)
                part.sortNo = clonedEntity.parts.length ? Math.max(...clonedEntity.parts.map((i) => i.sortNo)) + 1 : 1
                if (clonedEntity.isAudaNet) {
                  part.lineNumber = 0
                } else {
                  part.lineNumber = nextLineNumber
                }
                if (clonedEntity.isAudanet) {
                  part.rev = QuoteItemRevTypes.NonAudaNetPart
                }
              } else {
                part = clonedEntity.parts.find((i) => i.quoteItemId === partsCheckPart.quoteItemId)
                part.itemStatus = 'C'
              }

              if (countryCode !== 'MY' || (countryCode === 'MY' && partsCheckPart.isNew)) {
                part.value = partsCheckPart.unitPrice
                part.markupPercent = partsCheckPart.markupPercent
                part.markupValue = partsCheckPart.markupPrice
                part.mark = partsCheckPart.mark
              }

              part.itemQuantity = partsCheckPart.quantity
              part.buyPrice = partsCheckPart.buyPrice
              part.partNo = partsCheckPart.partNo
              part.side = partsCheckPart.side
              part.extLineId = partsCheckPart.partsCheckId
              part.partStatus =
                part.itemComment === 'Part price variance - Not Authorised/Invoiced?' && part.reportOnly === true
                  ? ''
                  : part.partStatus !== 'Pord'
                  ? 'Imp'
                  : part.partStatus // don't update parts status for pending order
              if (part.isNew) {
                if (countryCode === 'MY') {
                  part.itemComment = 'Part added by supplier'
                  part.reportOnly = true
                } else {
                  part.itemComment = partsCheckPart.itemComment
                  part.reportOnly = partsCheckPart.reportOnly
                }
                clonedEntity.parts.splice(clonedEntity.parts.length, 1, part)
                if (!clonedEntity.isAudaNet) {
                  clonedEntity.lines = clonedEntity.nextLineNumber + 1
                  nextLineNumber++
                }
              }
            })

            this.entity = clonedEntity
            await this.save()
            this.showSuccessToast('PartsCheck price updates downloaded', 5000)
          } else {
            this.$toast.open({
              message: 'There are no PartsCheck price updates',
              type: 'is-warning',
              queue: true,
              duration: 5000
            })
          }
        } catch {
        } finally {
          this.$hideSpinner()
        }
      } else {
        this.showQuoteChangedToast()
      }

      this.updateStatusForQuote()

      this.$nextTick(() => {
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
      })
    },

    async updateStatusForQuote() {
      const status = await this.fetchStatusForNumbers()
      // filter keys that have a value of "Withdrawn"
      const keys = Object.keys(status).filter((key) => status[key] == 'Withdrawn')

      if (!!keys.length) {
        this.$toast.open({
          message: 'Some parts have been withdrawn from PartsCheck. These can be priced and ordered again.',
          type: 'is-success',
          queue: true,
          position: 'is-bottom',
          duration: 5000
        })

        // update the status of the parts
        this.entity.parts.forEach((part) => {
          if (keys.includes(part.partsCheckReferenceNo.toString())) {
            part.partStatus = ''
          }
        })

        await this.save()
      }
    },
    viewPartsCheckRequest() {
      this.messageType = 'PartsCheck'
      this.isXmlViewerModalActive = true
    },
    // call to get status for a partscheck order, direct purchases and price parts
    async getStatusForNumber(refNumber) {
      const quoteNo = this.entity.prefix + this.entity.quoteNo + this.entity.suffix
      try {
        const status = await PartsCheckService.getQuotePartStatus(quoteNo, this.entity.subQuoteNo, refNumber)
        return { refNumber, status }
      } catch (error) {
        // Handle any errors if needed
        this.$notification.error('Error', 'Failed to get status for Quote Parts')
      }
    },
    async fetchStatusForNumbers() {
      let results = {}
      const numbers = [...new Set(this.entity.parts.map((i) => i.partsCheckReferenceNo))]
      for (const number of numbers) {
        const result = await this.getStatusForNumber(number)
        results[number] = result?.status
      }
      return results
    },

    addNewQuoteItems(existingItems, templateItems) {
      const existingItemsMap = new Map(existingItems.map((item) => [`${item.itemNo}|${item.itemDesc}|${item.itemType}`, item]))

      templateItems.forEach((templateItem) => {
        const key = `${templateItem.itemNo}|${templateItem.itemDesc}|${templateItem.itemType}`
        if (existingItemsMap.has(key)) return

        let newQuoteItem = new QuoteItemModel(this.entity.quoteId, templateItem.itemNo, templateItem.itemDesc, templateItem.itemType)
        newQuoteItem.reportOnly = templateItem.reportOnly
        newQuoteItem.value = templateItem.value

        if (templateItem.itemType == ItemCategoryTypes.PAINT) {
          newQuoteItem.opgCode = this.vehicle.paintGroup
        }

        existingItems.push(newQuoteItem)
        this.templateQuoteItemCount++
      })
    },

    async addRepairQuoteTemplateItems(id) {
      const templateItems = await QuoteTemplateService.getSortedQuoteTemplateItems(id)
      const LABOUR_ITEM_TYPES = new Set([ItemCategoryTypes.PAINT, ItemCategoryTypes.REP, ItemCategoryTypes.RR])
      const labourItems = templateItems.filter((i) => LABOUR_ITEM_TYPES.has(i.itemType))
      const miscItems = templateItems.filter((i) => i.itemType == ItemCategoryTypes.MISC)
      const otherItems = templateItems.filter((i) => i.itemType == ItemCategoryTypes.MECH)
      const subletItems = templateItems.filter((i) => i.itemType == ItemCategoryTypes.SUBL)

      this.addNewQuoteItems(this.entity.labours, labourItems)
      this.addNewQuoteItems(this.entity.miscs, miscItems)
      this.addNewQuoteItems(this.entity.others, otherItems)
      this.addNewQuoteItems(this.entity.sublets, subletItems)

      this.countNewQuoteItemNotification()
      this.templateQuoteItemCount = 0
    },

    countNewQuoteItemNotification() {
      if (!this.templateQuoteItemCount > 0) {
        return this.$toast.open({
          message: 'No item added',
          type: 'is-warning',
          position: 'is-bottom',
          queue: false
        })
      }
      return this.$toast.open({
        message: `${this.templateQuoteItemCount} Items added`,
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    }
  },
  async beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave')
    const isEditInsurer = to.name === InsurerRoutes.InsurerDetail.name
    const isEditVehile = to.name === VehicleRoutes.VehicleDetail.name

    if (isEditInsurer || isEditVehile) {
      next()
    } else if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.readOnlyView) {
      console.log(this.snapshotDiff)
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      this.setReturnRoute({})
      this.clearSessionStorage()
      this.clearSnapshots(from.params.quoteId)
      this.clearHeaders()
      this.clearInvoiceState()
      next()
    }
  },
  async beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    const isWithinQuote = QuoteRoutes.hasOwnProperty(to.name) && to.params.quoteId === from.params.quoteId
    const isWithinQuoteGroup = QuoteRoutes.hasOwnProperty(to.name) && to.params.quoteId !== from.params.quoteId

    const isPartsControlRoute = to.name === QuoteRoutes.QuotePartsControl.name

    if (isWithinQuote) {
      console.log('isNextRouteWithinQuote')
      // Ignore snapshotDiff within quote routes except for parts control
      if (isPartsControlRoute && this.snapshotDiff) {
        // try to save the quote
        const saveStatus = await this.save()
        if (!saveStatus) {
          this.$notification.error('Error', 'Save failed. Save the quote before making changes in parts control')
        }
        next()
      } else next()
    } else if (isWithinQuoteGroup) {
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.readOnlyView) {
        console.log('switch', this.snapshotDiff)
        this.$router.replace(from.path)
        this.toRoute = to
        this.isUnsavedModalActive = true
      } else {
        // Switch quote without changing route
        console.log('switch')
        this.$showSpinner()
        this.setPreviousQuoteId(from.params.quoteId)
        this.isSkipSave = false
        this.toRoute = null
        this.clearSessionStorage()
        this.clearSnapshots(from.params.quoteId)
        this.clearInvoiceState()
        await this.getStoreItem(to.params.quoteId)
        await this.getEntity()
        const imageIds = await QuoteService.getImageIds(this.entity.quoteId)
        this.$eventHub.$emit(EventHubTypes.ImageCountChanged, imageIds.length)
        const attachments = await QuoteService.getQuoteAttachments(this.entity.quoteId)
        let count = Object.values(attachments.data).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
        this.$eventHub.$emit(EventHubTypes.DocumentCountChanged, count)
        this.$v.entity.$reset()
        if (!this.entity.isNew) {
          // console.log('switch hhh')
          this.setReturnRoute({})
        }
        this.$hideSpinner()
        next()
        this.setPreviousQuoteId('')
      }
    }
    // else if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
    //   console.log(this.snapshotDiff)
    //   this.$router.replace(from.path)
    //   this.toRoute = to
    //   this.isUnsavedModalActive = true
    // } else {
    //   // console.log('next', to, from)
    //   this.clearSnapshots(this.entity.quoteId)
    //   this.clearSessionStorage()
    //   next()
    // }
  }
}
</script>
